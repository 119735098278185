var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.folderShow,
              expression: "folderShow"
            }
          ],
          staticClass: "filter-container"
        },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.showUploadFile } },
            [_c("a-icon", { attrs: { type: "upload" } }), _vm._v(" 上传 ")],
            1
          ),
          _c(
            "a-button",
            { on: { click: _vm.addFile } },
            [_c("a-icon", { attrs: { type: "plus" } }), _vm._v(" 新建文件夹 ")],
            1
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectionFlag,
                  expression: "selectionFlag"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.operateBatch("copy")
                }
              }
            },
            [_vm._v(" 复制 ")]
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectionFlag,
                  expression: "selectionFlag"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.operateBatch("move")
                }
              }
            },
            [_vm._v(" 移动 ")]
          ),
          _c(
            "a-popconfirm",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectionFlag,
                  expression: "selectionFlag"
                }
              ],
              attrs: {
                title: "确认要删除选中的记录吗？",
                "ok-text": "确认",
                "cancel-text": "取消"
              },
              on: { confirm: _vm.delBatch }
            },
            [_c("a-button", [_vm._v("删除")])],
            1
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectionFlag,
                  expression: "selectionFlag"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.downFile(null, 1)
                }
              }
            },
            [_vm._v(" 下载 ")]
          ),
          _c(
            "span",
            { staticStyle: { float: "right" } },
            [
              _c(
                "a-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "搜索" },
                  model: {
                    value: _vm.fileName,
                    callback: function($$v) {
                      _vm.fileName = $$v
                    },
                    expression: "fileName"
                  }
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "prefix", type: "search" },
                    slot: "prefix"
                  })
                ],
                1
              ),
              _c(
                "a-tooltip",
                { attrs: { title: "搜索" } },
                [
                  _c(
                    "a-button",
                    { on: { click: _vm.selectFileslist } },
                    [_c("a-icon", { attrs: { type: "search" } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-button-group",
                { staticStyle: { "margin-bottom": "2px" } },
                [
                  _c(
                    "a-tooltip",
                    { attrs: { title: "列表展示" } },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showStatus(true)
                            }
                          }
                        },
                        [_c("a-icon", { attrs: { type: "menu" } })],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tooltip",
                    { attrs: { title: "图标展示" } },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showStatus(false)
                            }
                          }
                        },
                        [_c("a-icon", { attrs: { type: "appstore" } })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tooltip",
                { attrs: { title: "刷新" } },
                [
                  _c(
                    "a-button",
                    { on: { click: _vm.reload } },
                    [_c("a-icon", { attrs: { type: "sync" } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tooltip",
                { attrs: { title: "回收站" } },
                [
                  _c(
                    "a-button",
                    { on: { click: _vm.showRecycleBin } },
                    [_c("a-icon", { attrs: { type: "delete" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "xq-auto-content",
              staticStyle: { "margin-top": "20px" }
            },
            [
              _vm.fileListShow
                ? _c("a-table", {
                    staticClass: "xq-auto-content-table",
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.fileList,
                      pagination: _vm.pagination,
                      "row-selection": {
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectChange
                      },
                      "row-key": "id",
                      size: "middle"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "name",
                          fn: function(text, record) {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectCurrentRow(record)
                                    }
                                  }
                                },
                                [_c("smallIcon", { attrs: { row: record } })],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "size",
                          fn: function(text, record) {
                            return [
                              record.size !== 0
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(record.size) +
                                        _vm._s(record.unit) +
                                        " "
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "action",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.copyFile(record.id)
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.moveFile(record.id)
                                    }
                                  }
                                },
                                [_vm._v("移动")]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.updateFile(record.id)
                                    }
                                  }
                                },
                                [_vm._v("重命名")]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认要删除记录吗？",
                                    "ok-text": "确认",
                                    "cancel-text": "取消"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.delFile(record)
                                    }
                                  }
                                },
                                [_c("a", [_vm._v("删除")])]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.downFile(record.id, 2)
                                    }
                                  }
                                },
                                [_vm._v("下载")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3034392787
                    )
                  })
                : _vm._e(),
              !_vm.fileListShow
                ? _c(
                    "div",
                    {
                      staticClass: "filter-container",
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "300px"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c("a-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple-dark" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [_vm._v("图标展示")]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._l(_vm.fileList, function(item) {
                        return _c(
                          "div",
                          { key: item.id },
                          [
                            _c("a-tooltip", { attrs: { title: item.name } }, [
                              _c(
                                "div",
                                { staticClass: "file-item" },
                                [
                                  item.directory === false
                                    ? [
                                        _c("bigIcon", {
                                          attrs: { row: item },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.previewFile(item.id)
                                            }
                                          }
                                        })
                                      ]
                                    : [
                                        _c("bigIcon", {
                                          attrs: { row: item },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.selectCurrentRow(item)
                                            }
                                          }
                                        })
                                      ]
                                ],
                                2
                              )
                            ])
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "xq-auto-content-pagination" },
                [
                  _c(
                    "div",
                    { staticClass: "xq-auto-content-pagination-total" },
                    [_vm._v("共 " + _vm._s(_vm.total) + " 条")]
                  ),
                  _c("a-pagination", {
                    attrs: {
                      "page-size": _vm.pageSize,
                      total: _vm.total,
                      "show-size-changer": true,
                      "show-quick-jumper": true,
                      "page-size-options": ["10", "20", "50"]
                    },
                    on: {
                      change: _vm.onPageChange,
                      showSizeChange: _vm.onPageSizeChange
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.recycleBinShow,
              expression: "recycleBinShow"
            }
          ],
          staticClass: "filter-container"
        },
        [_c("recycleBin", { ref: "recycleBin" })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.historyShow,
              expression: "historyShow"
            }
          ],
          staticClass: "filter-container"
        },
        [_c("history", { ref: "history" })],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.dialogType === "edit" ? "重命名" : "新建文件夹",
            "ok-text": _vm.dialogType === "edit" ? "重命名" : "新建",
            width: 600
          },
          on: {
            ok: _vm.confirmFile,
            cancel: function($event) {
              _vm.dialogVisible = false
            }
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                model: _vm.fileInfo,
                "label-col": { span: 5 },
                "wrapper-col": { span: 16 }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "文件名" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "文件夹名称" },
                    model: {
                      value: _vm.fileInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.fileInfo, "name", $$v)
                      },
                      expression: "fileInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("a-input-number", {
                    model: {
                      value: _vm.fileInfo.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.fileInfo, "sort", $$v)
                      },
                      expression: "fileInfo.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.dialogVisibleFile, title: "上传文件" },
          on: {
            ok: function($event) {
              _vm.dialogVisibleFile = false
            },
            cancel: function($event) {
              _vm.dialogVisibleFile = false
            }
          }
        },
        [
          _c("uploadGeneralFile", {
            ref: "uploader",
            staticClass: "custom-uploader",
            attrs: {
              limit: 99,
              draggable: true,
              url:
                "/file/api/v1/private/upload/" +
                _vm.fileInfo.repoId +
                "/" +
                _vm.fileInfo.parentId,
              customHandler: _vm.onUploadFile,
              accept: "*"
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.dialogVisibleOperate, title: "选择目标文件" },
          on: {
            ok: _vm.pasteFile,
            cancel: function($event) {
              _vm.dialogVisibleOperate = false
            }
          }
        },
        [
          _c(
            "a-tree",
            {
              attrs: {
                "load-data": _vm.onLoadData,
                "tree-data": _vm.treeData,
                "expanded-keys": _vm.expandedKeys,
                "selected-keys": _vm.selectedKeys,
                "show-icon": ""
              },
              on: {
                "update:expandedKeys": function($event) {
                  _vm.expandedKeys = $event
                },
                "update:expanded-keys": function($event) {
                  _vm.expandedKeys = $event
                },
                "update:selectedKeys": function($event) {
                  _vm.selectedKeys = $event
                },
                "update:selected-keys": function($event) {
                  _vm.selectedKeys = $event
                },
                select: _vm.onNodeSelect
              }
            },
            [
              _c("a-icon", {
                attrs: { slot: "shop", type: "shop" },
                slot: "shop"
              }),
              _c("a-icon", {
                attrs: { slot: "database", type: "database" },
                slot: "database"
              }),
              _c("a-icon", {
                attrs: { slot: "folder", type: "folder" },
                slot: "folder"
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }