var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-page" },
    [
      _c(
        "div",
        { staticClass: "nav-box" },
        [
          _c("a-icon", { attrs: { type: "left" }, on: { click: _vm.goBack } }),
          _vm._v(" " + _vm._s(_vm.title) + "详情 ")
        ],
        1
      ),
      _c(
        "a-divider",
        { staticClass: "header pt24", attrs: { orientation: "left" } },
        [_c("span"), _vm._v(" 基本信息 ")]
      ),
      _c(
        "a-row",
        [
          _c("a-col", { staticClass: "header", attrs: { span: 12 } }, [
            _vm._v(_vm._s(_vm.info.gsmc))
          ])
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("官网地址：")]),
            _vm._v(" " + _vm._s(_vm.info.gsgw) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("统一社会信用代码：")]),
            _vm._v(" " + _vm._s(_vm.info.tyshxydm) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("公司法人：")]),
            _vm._v(" " + _vm._s(_vm.info.frxm) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("联系电话：")]),
            _vm._v(" " + _vm._s(_vm.info.frdh) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("财务姓名：")]),
            _vm._v(" " + _vm._s(_vm.info.cwxm) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("联系电话：")]),
            _vm._v(" " + _vm._s(_vm.info.cwdh) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("公司规模：")]),
            _vm._v(" " + _vm._s(_vm.info.gsgm) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("所在行业：")]),
            _vm._v(" " + _vm._s(_vm.info.sshy) + " ")
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex" } },
                [
                  _c(
                    "a-col",
                    { staticClass: "text", attrs: { flex: "80px" } },
                    [_vm._v("经营范围：")]
                  ),
                  _c(
                    "a-col",
                    { staticClass: "w300", attrs: { flex: "auto" } },
                    [_vm._v(_vm._s(_vm.info.jyfw))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-divider",
        { staticClass: "header pt24", attrs: { orientation: "left" } },
        [_c("span"), _vm._v("材料附件 ")]
      ),
      _c(
        "a-row",
        { staticClass: "text-center" },
        [
          _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
            _c("a", { attrs: { href: _vm.info.frsfzzm, target: "_blank" } }, [
              _c("img", {
                staticClass: "w185",
                attrs: { src: _vm.info.frsfzzm }
              })
            ]),
            _c("p", [_vm._v("法人身份证正面")])
          ]),
          _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
            _c("a", { attrs: { href: _vm.info.frsfzfm, target: "_blank" } }, [
              _c("img", {
                staticClass: "w185",
                attrs: { src: _vm.info.frsfzfm }
              })
            ]),
            _c("p", [_vm._v("法人身份证反面")])
          ]),
          _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
            _c("a", { attrs: { href: _vm.info.cwsfzzm, target: "_blank" } }, [
              _c("img", {
                staticClass: "w185",
                attrs: { src: _vm.info.cwsfzzm }
              })
            ]),
            _c("p", [_vm._v("财务身份证正面")])
          ]),
          _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
            _c("a", { attrs: { href: _vm.info.cwsfzfm, target: "_blank" } }, [
              _c("img", {
                staticClass: "w185",
                attrs: { src: _vm.info.cwsfzfm }
              })
            ]),
            _c("p", [_vm._v("财务身份证反面")])
          ]),
          _c("a-col", { staticClass: "text", attrs: { span: 4 } }, [
            _c("a", { attrs: { href: _vm.info.yyzz, target: "_blank" } }, [
              _c("img", { staticClass: "w185", attrs: { src: _vm.info.yyzz } })
            ]),
            _c("p", [_vm._v("营业执照")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }