var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "filter-container" }, [
      _c(
        "span",
        [
          _c(
            "a-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.oprType,
                callback: function($$v) {
                  _vm.oprType = $$v
                },
                expression: "oprType"
              }
            },
            [
              _c("a-select-option", { key: "all", attrs: { value: "" } }, [
                _vm._v("全部")
              ]),
              _vm._l(_vm.oprTypeOptions, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "a-tooltip",
            { attrs: { title: "搜索" } },
            [
              _c(
                "a-button",
                { on: { click: _vm.selectFileslist } },
                [_c("a-icon", { attrs: { type: "search" } })],
                1
              )
            ],
            1
          ),
          _c(
            "a-button-group",
            { staticStyle: { "margin-bottom": "2px" } },
            [
              _c(
                "a-tooltip",
                { attrs: { title: "列表展示" } },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.showStatus(true)
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "menu" } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tooltip",
                { attrs: { title: "图标展示" } },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.showStatus(false)
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "appstore" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tooltip",
            { attrs: { title: "刷新" } },
            [
              _c(
                "a-button",
                { on: { click: _vm.reload } },
                [_c("a-icon", { attrs: { type: "sync" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "xq-auto-content",
          staticStyle: { "margin-top": "20px" }
        },
        [
          _c("a-table", {
            staticClass: "xq-auto-content-table",
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.fileList,
              pagination: _vm.pagination,
              "row-key": "id",
              size: "middle"
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(text, record) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.selectCurrentRow(record)
                          }
                        }
                      },
                      [
                        _c("smallIcon", {
                          attrs: { row: { name: record.newFileName } }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "gmtCreate",
                fn: function(text) {
                  return [_c("DateCell", { attrs: { value: text } })]
                }
              },
              {
                key: "size",
                fn: function(text, record) {
                  return [
                    record.size !== 0
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(record.size) +
                              _vm._s(record.unit) +
                              " "
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "xq-auto-content-pagination" },
            [
              _c("div", { staticClass: "xq-auto-content-pagination-total" }, [
                _vm._v("共 " + _vm._s(_vm.total) + " 条")
              ]),
              _c("a-pagination", {
                attrs: {
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  "show-size-changer": true,
                  "show-quick-jumper": true,
                  "page-size-options": ["10", "20", "50"]
                },
                on: {
                  change: _vm.onPageChange,
                  showSizeChange: _vm.onPageSizeChange
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }