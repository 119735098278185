var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-container xq-auto-wrapper" },
    [
      _c("a-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "clearfix", attrs: { slot: "title" }, slot: "title" },
          [
            _c(
              "a-row",
              [
                _c(
                  "a-breadcrumb",
                  { attrs: { separator: ">" } },
                  [
                    _c("a-breadcrumb-item", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toPath(0, "")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.projectName))]
                      )
                    ]),
                    _vm._l(_vm.breadListLast, function(item) {
                      return _c("a-breadcrumb-item", { key: item.id }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toPath(1, item.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ])
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            this.fileInfo.repoId
              ? _c("folder", {
                  ref: "folder",
                  attrs: { "file-object": _vm.fileInfo },
                  on: { listenTochildEvent: _vm.listenTochildEvent }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }