var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-page" },
    [
      _c(
        "div",
        { staticClass: "nav-box" },
        [
          _c("a-icon", { attrs: { type: "left" }, on: { click: _vm.goBack } }),
          _vm._v(" 上游任务详情 ")
        ],
        1
      ),
      _c(
        "a-divider",
        { staticClass: "header pt24", attrs: { orientation: "left" } },
        [_c("span"), _vm._v(" 基本信息 ")]
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务名称：")]),
            _vm._v(" " + _vm._s(_vm.info.name) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务编号：")]),
            _vm._v(" " + _vm._s(_vm.info.taskNo) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务类型：")]),
            _vm._v(" " + _vm._s(_vm.info.type) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务金额：")]),
            _vm._v(" " + _vm._s(_vm.info.amount) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务状态：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.statusList[_vm.info.status - 1] &&
                    _vm.statusList[_vm.info.status - 1].label
                ) +
                " "
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务开始时间：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.startDate &&
                    _vm.moment(_vm.info.startDate).format("YYYY-MM-DD")
                ) +
                " "
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("任务结束时间：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.endDate &&
                    _vm.moment(_vm.info.endDate).format("YYYY-MM-DD")
                ) +
                " "
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("分包时间：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.assignDate &&
                    _vm.moment(_vm.info.assignDate).format("YYYY-MM-DD")
                ) +
                " "
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("分包人：")]),
            _vm._v(" " + _vm._s(_vm.info.assignUserName) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("分包人数：")]),
            _vm._v(" " + _vm._s(_vm.info.assignCount) + " ")
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("审核时间：")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.examineDate &&
                    _vm.moment(_vm.info.examineDate).format("YYYY-MM-DD")
                ) +
                " "
            )
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "text" }, [_vm._v("审核人：")]),
            _vm._v(" " + _vm._s(_vm.info.examineUserName) + " ")
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex" } },
                [
                  _c(
                    "a-col",
                    { staticClass: "text", attrs: { flex: "80px" } },
                    [_vm._v("任务描述：")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "w300",
                      staticStyle: { "white-space": "pre-wrap" },
                      attrs: { flex: "auto" }
                    },
                    [_vm._v(_vm._s(_vm.info.description))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-divider",
        { staticClass: "header pt24", attrs: { orientation: "left" } },
        [_c("span"), _vm._v("材料附件 ")]
      ),
      _c(
        "a-row",
        { staticClass: "text-center" },
        [
          _vm.info.file
            ? _c(
                "a-col",
                { staticClass: "text", attrs: { span: 4 } },
                [
                  _c("UploadSiteFile", {
                    attrs: { defaultValue: _vm.info.file }
                  }),
                  _c("p", [_vm._v("附件")])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }