var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-bg p20" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            [
              _c("a-table", {
                staticClass: "table-margin-top",
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.dataArray,
                  pagination: _vm.pagination,
                  "row-key": "id"
                },
                on: { change: _vm.handleTableChange },
                scopedSlots: _vm._u([
                  {
                    key: "file",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: record.link, target: "_blank" } },
                          [_vm._v(_vm._s(text))]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }