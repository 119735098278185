var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "a-popconfirm",
          {
            attrs: { title: "确认要清空回收站吗？清空后将无法恢复" },
            on: { confirm: _vm.clear }
          },
          [_c("a-button", [_vm._v("清空回收站")])],
          1
        ),
        _c(
          "a-popconfirm",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectionFlag,
                expression: "selectionFlag"
              }
            ],
            attrs: {
              title: "确认要彻底删除选中的文件吗？删除后将无法恢复",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: { confirm: _vm.delBatch }
          },
          [_c("a-button", [_vm._v("彻底删除")])],
          1
        ),
        _c(
          "a-popconfirm",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectionFlag,
                expression: "selectionFlag"
              }
            ],
            attrs: {
              title: "确认要还原选中的文件吗？",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: { confirm: _vm.restoreBatch }
          },
          [_c("a-button", [_vm._v("批量还原")])],
          1
        ),
        _c(
          "span",
          { staticStyle: { float: "right" } },
          [
            _c(
              "a-input",
              {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "搜索" },
                model: {
                  value: _vm.fileName,
                  callback: function($$v) {
                    _vm.fileName = $$v
                  },
                  expression: "fileName"
                }
              },
              [
                _c("a-icon", {
                  attrs: { slot: "prefix", type: "search" },
                  slot: "prefix"
                })
              ],
              1
            ),
            _c(
              "a-tooltip",
              { attrs: { title: "搜索" } },
              [
                _c(
                  "a-button",
                  { on: { click: _vm.selectFileslist } },
                  [_c("a-icon", { attrs: { type: "search" } })],
                  1
                )
              ],
              1
            ),
            _c(
              "a-button-group",
              { staticStyle: { "margin-bottom": "2px" } },
              [
                _c(
                  "a-tooltip",
                  { attrs: { title: "列表展示" } },
                  [
                    _c(
                      "a-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showStatus(true)
                          }
                        }
                      },
                      [_c("a-icon", { attrs: { type: "menu" } })],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-tooltip",
                  { attrs: { title: "图标展示" } },
                  [
                    _c(
                      "a-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showStatus(false)
                          }
                        }
                      },
                      [_c("a-icon", { attrs: { type: "appstore" } })],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-tooltip",
              { attrs: { title: "刷新" } },
              [
                _c(
                  "a-button",
                  { on: { click: _vm.reload } },
                  [_c("a-icon", { attrs: { type: "sync" } })],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "xq-auto-content",
            staticStyle: { "margin-top": "20px" }
          },
          [
            _vm.fileListShow
              ? _c("a-table", {
                  staticClass: "xq-auto-content-table",
                  attrs: {
                    columns: _vm.columns,
                    "data-source": _vm.fileList,
                    pagination: _vm.pagination,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    },
                    "row-key": "id",
                    size: "middle"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "name",
                        fn: function(text, record) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectCurrentRow(record)
                                  }
                                }
                              },
                              [_c("smallIcon", { attrs: { row: record } })],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "size",
                        fn: function(text, record) {
                          return [
                            record.size != 0
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.size) +
                                      _vm._s(record.unit) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "action",
                        fn: function(text, record) {
                          return [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确定要彻底删除该文件, 是否继续?",
                                  "ok-text": "确认",
                                  "cancel-text": "取消"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delFile(record.id)
                                  }
                                }
                              },
                              [_c("a", [_vm._v("彻底删除")])]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确定要还原该文件, 是否继续?",
                                  "ok-text": "确认",
                                  "cancel-text": "取消"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.restoreFile(record.id)
                                  }
                                }
                              },
                              [_c("a", [_vm._v("还原")])]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2861061738
                  )
                })
              : _vm._e(),
            !_vm.fileListShow
              ? _c(
                  "div",
                  {
                    staticClass: "filter-container",
                    staticStyle: {
                      "margin-top": "20px",
                      "margin-bottom": "300px"
                    }
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple-dark" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("图标展示")]
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._l(_vm.fileList, function(item) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _c("a-tooltip", { attrs: { title: item.name } }, [
                            _c(
                              "div",
                              { staticClass: "file-item" },
                              [
                                item.directory === false
                                  ? [
                                      _c("bigIcon", {
                                        attrs: { row: item },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.previewFile(item.id)
                                          }
                                        }
                                      })
                                    ]
                                  : [
                                      _c("bigIcon", {
                                        attrs: { row: item },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.selectCurrentRow(item)
                                          }
                                        }
                                      })
                                    ]
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "xq-auto-content-pagination" },
              [
                _c("div", { staticClass: "xq-auto-content-pagination-total" }, [
                  _vm._v("共 " + _vm._s(_vm.total) + " 条")
                ]),
                _c("a-pagination", {
                  attrs: {
                    "page-size": _vm.pageSize,
                    total: _vm.total,
                    "show-size-changer": true,
                    "show-quick-jumper": true,
                    "page-size-options": ["10", "20", "50"]
                  },
                  on: {
                    change: _vm.onPageChange,
                    showSizeChange: _vm.onPageSizeChange
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }