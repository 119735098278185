var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { staticClass: "xq-auto-wrapper", attrs: { spinning: _vm.spinning } },
    [
      _c(
        "div",
        { staticClass: "xq-auto-search" },
        [
          _c("search-form", {
            attrs: { "source-form": _vm.RecordForm },
            on: { search: _vm.filterRecord }
          })
        ],
        1
      ),
      _c("div", { staticClass: "xq-auto-action" }, [
        _c(
          "div",
          { staticClass: "xq-auto-action-left" },
          [
            _vm.RecordForm.config.customize.batch
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.showBatchEditModal()
                      }
                    }
                  },
                  [_vm._v(" 批量添加 ")]
                )
              : _vm._e(),
            _vm.RecordForm.config.customize.batch
              ? _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length <= 0,
                      title: "确认要删除选中的记录吗？",
                      "ok-text": "确认",
                      "cancel-text": "取消"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.deleteBatchRecord()
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          disabled: _vm.selectedRowKeys.length <= 0,
                          type: "primary"
                        }
                      },
                      [_vm._v(" 批量删除 ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.RecordForm.config.customize.batch
              ? _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length <= 0,
                      type: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showBatchEditModal(_vm.selectedRowKeys)
                      }
                    }
                  },
                  [_vm._v(" 批量编辑 ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "xq-auto-action-right" })
      ]),
      _c(
        "div",
        { staticClass: "xq-auto-content" },
        [
          _c(
            "a-table",
            {
              staticClass: "xq-auto-content-table",
              attrs: {
                scroll: { x: _vm.columns.length * 150, y: 500 },
                columns: _vm.columns,
                "data-source": _vm.tableData,
                pagination: _vm.pagination,
                "row-selection": _vm.RecordForm.config.customize.batch
                  ? {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    }
                  : null,
                "row-key": "id"
              },
              on: { change: _vm.onTableChange },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.columns, function(col, index) {
                    return {
                      key: col.dataIndex,
                      fn: function(text, record) {
                        return [
                          col.dataIndex === "crudAction"
                            ? _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确认要删除记录吗？",
                                        "ok-text": "确认",
                                        "cancel-text": "取消"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.deleteRecord(record)
                                        }
                                      }
                                    },
                                    [_c("a", [_vm._v("删除")])]
                                  )
                                ],
                                1
                              )
                            : col.dataIndex === "name"
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "xq-auto-content-table-cell",
                                  staticStyle: { width: "118px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.detailRecord(record)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(text))]
                              )
                            : col.dataIndex === "file" && text
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  staticClass:
                                    "xq-auto-content-table-cell clickable",
                                  staticStyle: { width: "118px" }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm._f("textFilder")(text),
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" 《" + _vm._s(record.name) + "》")]
                                  )
                                ]
                              )
                            : _c("table-cell", {
                                key: index,
                                attrs: {
                                  text: text,
                                  record: record,
                                  column: col,
                                  dynamicData: _vm.dynamicData
                                }
                              })
                        ]
                      }
                    }
                  })
                ],
                null,
                true
              )
            },
            [
              _vm._l(_vm.columns, function(col, index) {
                return _c("template", { slot: "custom_" + col.dataIndex }, [
                  _c(
                    "span",
                    { key: index },
                    [
                      col.help
                        ? _c(
                            "a-tooltip",
                            { attrs: { title: col.help } },
                            [
                              _c("a-icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { type: "question-circle" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(col.customTitle) + " ")
                    ],
                    1
                  )
                ])
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "xq-auto-content-pagination" },
            [
              _c("div", { staticClass: "xq-auto-content-pagination-total" }, [
                _vm._v("共 " + _vm._s(_vm.total) + " 条")
              ]),
              _c("a-pagination", {
                attrs: {
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  "show-size-changer": true,
                  "show-quick-jumper": true,
                  "page-size-options": ["100", "20", "50"]
                },
                on: {
                  change: _vm.onPageChange,
                  showSizeChange: _vm.onPageSizeChange
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title:
              _vm.editModalType === "create"
                ? "创建上游客户开票记录"
                : "编辑上游客户开票记录",
            width: 800
          },
          on: { cancel: _vm.editCancel, ok: _vm.editOk },
          model: {
            value: _vm.editModalVisible,
            callback: function($$v) {
              _vm.editModalVisible = $$v
            },
            expression: "editModalVisible"
          }
        },
        [
          _vm.jsonData
            ? _c("k-form-build", {
                ref: "kfb",
                attrs: { value: _vm.jsonData, dynamicData: _vm.dynamicData },
                on: {
                  change: function($event) {
                    return _vm.onFormChange(arguments[0], arguments[1], "kfb")
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-modal",
        {
          staticClass: "batch-form-modal",
          attrs: {
            title:
              _vm.batchEditModalType === "create"
                ? "批量创建上游客户开票记录"
                : "批量编辑上游客户开票记录",
            width: 1200
          },
          on: { cancel: _vm.batchEditCancel, ok: _vm.batchEditOk },
          model: {
            value: _vm.batchEditModalVisible,
            callback: function($$v) {
              _vm.batchEditModalVisible = $$v
            },
            expression: "batchEditModalVisible"
          }
        },
        [
          _vm.batchJsonDatas.length > 0
            ? _c("div", { staticClass: "batch-form-wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "batch-marking",
                    style: "height: " + _vm.batchHeight + "px"
                  },
                  [
                    _c("div", { staticClass: "batch-marking-header" }),
                    _vm._l(_vm.batchJsonDatas, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "batch-marking-item" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item["list"][0]["options"]["defaultValue"]
                              ) +
                              " "
                          ),
                          _c("a-icon", {
                            attrs: { type: "close-circle" },
                            on: {
                              click: function($event) {
                                return _vm.removeBatchForm(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { ref: "batchForm", staticClass: "batch-form" },
                  [
                    _vm._l(_vm.batchJsonDatas, function(item, index) {
                      return [
                        _c("k-form-build", {
                          key: index,
                          ref: "batchKfb" + index,
                          refInFor: true,
                          class: { noLabel: index > 0 },
                          attrs: { value: item, dynamicData: _vm.dynamicData },
                          on: {
                            change: function($event) {
                              return _vm.onFormChange(
                                arguments[0],
                                arguments[1],
                                "batchKfb" + index,
                                true
                              )
                            }
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.batchEditModalType === "create"
            ? _c("a-button", { on: { click: _vm.addBatchCreateForm } }, [
                _vm._v(" 添加 ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }