"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "合同名称",
  dataIndex: "agree"
}, {
  title: "公司",
  dataIndex: "name"
}, {
  title: "合同原件",
  dataIndex: "file",
  scopedSlots: {
    customRender: "file"
  }
}, {
  title: "签约时间",
  dataIndex: "time"
}];
var _default = {
  inject: ["reload"],
  data: function data() {
    var _this = this;

    return {
      communityId: this.$store.state.pms ? this.$store.state.pms.communityId : null,
      searchFormInfo: {
        ref: null,
        data: {
          name: undefined,
          phone: undefined,
          ownerType: undefined
        },
        fieldList: [{
          label: "合同名称",
          value: "name",
          type: "input"
        }],
        submitText: "搜索",
        labelSpan: 6
      },
      columns: columns,
      dataArray: [],
      // 分页数据
      pagination: {
        current: 1,
        total: 10,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761\u6570\u636E");
        },
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100"],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.currentPageSize = pageSize;
        },
        showQuickJumper: true
      },
      queryParam: {
        communityId: this.$store.state.pms ? this.$store.state.pms.communityId : null,
        pageNo: 1,
        // 第几页
        pageSize: 10 // 每页中显示数据的条数

      }
    };
  },
  created: function created() {
    this.loadTableData();
  },
  methods: {
    loadTableData: function loadTableData() {
      var data = [{
        agree: "e签宝签约合同",
        name: "青岛京杰科技有限公司",
        code: "12312343232",
        time: "2020-03-14",
        status: "已认证",
        link: "https://res.lanxiang.co/resources/static/%E6%82%9F%E7%A9%BA%E7%BA%BF%E4%B8%8A%E5%90%88%E5%90%8CV%E5%B1%B1%E4%B8%9C%E5%8D%8E%E6%9D%B0%E4%BA%BA%E5%8A%9B.pdf",
        file: "<<e签宝签约合同>>"
      }, {
        agree: "百度人脸识别合同",
        name: "青岛京杰科技有限公司",
        code: "12312343232",
        time: "2020-03-14",
        status: "已认证",
        link: "https://console.bce.baidu.com/iam/agreement-v2.html",
        file: "<<百度人脸识别合同>>"
      }, {
        agree: "电子银行银联快付业务协议",
        name: "青岛京杰科技有限公司",
        code: "12312343232",
        time: "2020-03-14",
        status: "已认证",
        link: "https://res.lanxiang.co/prod/4ce0237f-7d5f-49dd-a8cd-f3ee6fcc1ab6/002590cf-f215-4c97-93c4-e3d2f78e6aa7.pdf",
        file: "<<电子银行银联快付业务协议>>"
      }, {
        agree: "中信银行银企直联合作协议",
        name: "青岛京杰科技有限公司",
        code: "12312343232",
        time: "2020-03-14",
        status: "已认证",
        link: "https://res.lanxiang.co/prod/967171d8-7767-4942-ad72-40a33c8273b7/12c8b9f0-d0ad-4334-a428-b2fa13edc593.pdf",
        file: "<<中信银行银企直联合作协议>>"
      }];
      this.dataArray = data;
      this.pagination.total = data.length;
    },
    // table点击事件
    handleTableChange: function handleTableChange(pagination) {
      // 更改分页参数
      this.pagination = pagination; // 更改查询表格参数

      this.queryParam.pageNo = pagination.current;
      this.queryParam.pageSize = pagination.pageSize; // 查询数据

      this.loadTableData();
    },
    search: function search() {
      // 更改分页参数
      this.pagination.current = 1; // 更改查询表格参数

      this.queryParam.pageNo = 1; // 设置搜索项

      for (var key in this.searchFormInfo.data) {
        if (this.searchFormInfo.data[key] !== undefined && this.searchFormInfo.data[key] !== "") {
          this.queryParam[key] = this.searchFormInfo.data[key];
        } else {
          this.queryParam[key] = undefined;
        }
      }

      this.loadTableData();
    },
    // 顶部组件重置事件
    reset: function reset() {
      this.searchFormInfo.data = {};
    },
    view: function view() {
      this.$router.push({
        path: "/appoint/index"
      });
    }
  }
};
exports.default = _default;