var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-bg page-model-box" },
    _vm._l(_vm.json, function(val, index) {
      return _c(
        "a-row",
        { key: index },
        [
          _c("h2", [_vm._v(_vm._s(val.title))]),
          _c(
            "a-row",
            { staticClass: "page-model-list-box" },
            _vm._l(val.item, function(result, idx) {
              return _c(
                "a-col",
                {
                  key: index + "-" + idx,
                  staticClass: "page-model-box-list",
                  on: {
                    click: function($event) {
                      return _vm.goto(result.path)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "./slices/" + result.title + "@2x.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" " + _vm._s(result.title) + " ")
                ]
              )
            }),
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }