var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-smallIcon" },
    [
      _vm.row.directory === false
        ? [
            _vm.row.ext === "ae"
              ? [_c("img", { attrs: { src: require("../images/ae.png") } })]
              : _vm.row.ext === "ai"
              ? [_c("img", { attrs: { src: require("../images/ai.png") } })]
              : _vm.row.ext === "apk"
              ? [_c("img", { attrs: { src: require("../images/apk.png") } })]
              : _vm.row.ext === "dmg"
              ? [_c("img", { attrs: { src: require("../images/dmg.png") } })]
              : _vm.row.ext === "doc" || _vm.row.ext === "docx"
              ? [_c("img", { attrs: { src: require("../images/doc.png") } })]
              : _vm.row.ext === "exe"
              ? [_c("img", { attrs: { src: require("../images/exe.png") } })]
              : _vm.row.ext === "mp3" ||
                _vm.row.ext === "midi" ||
                _vm.row.ext === "wma" ||
                _vm.row.ext === "vqf" ||
                _vm.row.ext === "amr"
              ? [_c("img", { attrs: { src: require("../images/mp3.png") } })]
              : _vm.row.ext === "pdf"
              ? [_c("img", { attrs: { src: require("../images/pdf.png") } })]
              : _vm.row.ext === "png" ||
                _vm.row.ext === "jpg" ||
                _vm.row.ext === "jpeg" ||
                _vm.row.ext === "gif"
              ? [_c("img", { attrs: { src: require("../images/png.png") } })]
              : _vm.row.ext === "ppt"
              ? [_c("img", { attrs: { src: require("../images/ppt.png") } })]
              : _vm.row.ext === "ps"
              ? [_c("img", { attrs: { src: require("../images/ps.png") } })]
              : _vm.row.ext === "rar" ||
                _vm.row.ext === "cab" ||
                _vm.row.ext === "tar" ||
                _vm.row.ext === "7z"
              ? [_c("img", { attrs: { src: require("../images/rar.png") } })]
              : _vm.row.ext === "sketch"
              ? [_c("img", { attrs: { src: require("../images/sketch.png") } })]
              : _vm.row.ext === "txt"
              ? [_c("img", { attrs: { src: require("../images/txt.png") } })]
              : _vm.row.ext === "url"
              ? [_c("img", { attrs: { src: require("../images/url.png") } })]
              : _vm.row.ext === "mp4" ||
                _vm.row.ext === "rm" ||
                _vm.row.ext === "rmvb" ||
                _vm.row.ext === "avi" ||
                _vm.row.ext === "flv" ||
                _vm.row.ext === "wmv" ||
                _vm.row.ext === "f4v" ||
                _vm.row.ext === "asf"
              ? [_c("img", { attrs: { src: require("../images/mp4.png") } })]
              : _vm.row.ext === "zip"
              ? [_c("img", { attrs: { src: require("../images/zip.png") } })]
              : _vm.row.ext === "xls" || _vm.row.ext === "xlsx"
              ? [_c("img", { attrs: { src: require("../images/xls.png") } })]
              : [
                  _c("img", {
                    attrs: { src: require("../images/default.png") }
                  })
                ]
          ]
        : [_c("img", { attrs: { src: require("../images/docu.png") } })],
      _vm._v(
        " " +
          _vm._s(_vm.row.name == null ? _vm.row.fileName : _vm.row.name) +
          " "
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }