"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = require("./api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var columns = [{
  title: "上游客户",
  dataIndex: "customerName",
  key: "customerName"
}, {
  title: "公司",
  dataIndex: "companyName",
  key: "companyName"
}, {
  title: "公司信息",
  dataIndex: "tags",
  scopedSlots: {
    customRender: "tags"
  }
}, {
  title: "公司银行信息",
  dataIndex: "bank",
  scopedSlots: {
    customRender: "bank"
  }
}, {
  title: "费率信息",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
var _default = {
  data: function data() {
    return {
      data: [],
      columns: columns
    };
  },
  created: function created() {
    var _this = this;

    (0, _api.retrieveReq)({
      pageNo: 1,
      pageSize: 100
    }).then(function (res) {
      _this.data = res.data.entities;
    });
  }
};
exports.default = _default;